const variantStore = {
	state : {
		variantToEdit : null,
	},
	mutations : {
		setVarianttoEdit(state, newVariant){
			state.variantToEdit = newVariant;
		}
	}
}

export default variantStore;