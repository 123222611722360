const userStore = {
	state : {
		user : null,
		userToEdit : null,
		groupToManage : null,
	},
	mutations : {
		login(state, newUser){
			state.user = newUser;
		},
		setUserToEdit(state, newUser){
			state.userToEdit = newUser;
		},
		setGroupToManage(state, newGroup){
			state.groupToManage = newGroup
		}
	}
}

export default userStore;