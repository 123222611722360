import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist';
import SecureLS from "secure-ls";
import plantingPlanStore from './plantingPlan';
import logisticsStore from './logistics';
import userStore from './users';
import harvestStore from './harvest';
import variantStore from './variants';

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    new VuexPersistence({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    }).plugin
  ],

  state() {
    return {
      ...plantingPlanStore.state,
      ...logisticsStore.state,
      ...userStore.state,
      ...harvestStore.state,
      ...variantStore.state
    }
  },
  mutations: {
    ...plantingPlanStore.mutations,
    ...logisticsStore.mutations,
    ...userStore.mutations,
    ...harvestStore.mutations,
    ...variantStore.mutations
    },
  actions: {},
  modules: {},
});
