<template>
  <header>
    <div class="header-dash">
      <router-link :to="{name: 'home'}" exact tag="button" class="header-dash__logo-btn">
        <img src="@/assets/XFarmsLogo.png" class="header-dash__side-logo" alt="" />
        <!-- <img src="xfarm.ico" class="header-dash__side-logo" alt="" /> -->
      </router-link>

      <v-select
        class="header-dash__farm-select"
        :clearable="false"
        :searchable="false"
        :options="farms"
        :disabled="farm_select"
        v-model="selectedFarm"
        v-on:input="updateSelectedfarm"
      />

      <div class="header-dash__profile-img" id="header-dash__profile-card-trigger" @click="toggleShowProfile()">
        <b-avatar size="3rem" class="user-avatar">{{
          user | initialsFN
        }}</b-avatar>
      </div>
      <hr />
      <div class="header-dash__profile-card" id="header-dash__profile-card" v-if="showProfile" >
        <b-avatar size="4rem" class="user-avatar" font-size="4rem">{{
          user | initialsFN
        }}</b-avatar>
        <p class="user-info">User {{ user }} as</p>
        <p class="user-role">{{ role }}</p>

        <b-button
          class="header-dash__logout-btn"
          size="sm"
          variant="danger"
          v-on:click.prevent="logout"
        >
          Log Out
        </b-button>
      </div>
    </div>
  </header>
</template>

<script>

export default {
  name: "TopBar",
  props: ["farms", "selected_farm", "farm_select", "user", "role"],
  data() {
    return {
      title: "",
      initials: "",
      showProfile: false,
      selectedFarm: this.selected_farm,
      farmSelect: this.farm_select
    };
  },
  watch:{
    selected_farm: function(newVal){
      this.selectedFarm = newVal;
    },
    farm_select: function(newVal){
      this.farmSelect = newVal;
    }
  },
  filters: {
    initialsFN: function(user){
      let initials = user.split(" ");
      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0);
      } else {
        initials = user.substring(0, 2);
      }
      return initials.toUpperCase();
    }
    //filter here will make letters uppercase
    //have to do like this since no function call therefore no return variable to
    //return toUppercase to (vue doesnt allow that)
  },
  methods: {    
    updateSelectedfarm() {
      this.$emit('updateFarm', this.selectedFarm);
    },
    toggleShowProfile() {
      this.showProfile = !this.showProfile;
    },
    logout(evt) {
      evt.preventDefault();
      this.$emit('logout');
    },
    handleProfileFocus: function(e){
      // header card focus
			const 	cardElement = document.getElementById('header-dash__profile-card');
      const   triggerElement = document.getElementById('header-dash__profile-card-trigger');

      if (triggerElement && triggerElement.contains(e.target)) return ;
      if (!cardElement) return;
      if (e.target != cardElement && !cardElement.contains(e.target) ) this.toggleShowProfile();
		}
  },
  created : function () {
		window.addEventListener('click', this.handleProfileFocus)
	},
	destroyed : function () {
		window.removeEventListener('click', this.handleProfileFocus)
	}

};
</script>

<style>
.header-dash {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  color: black;
  background: rgba(240, 255, 240, 0.7);
  margin: 0px;
  padding: 2px;
  box-sizing: border-box;
}
.header-dash * {
  margin: 5px;
  flex-shrink: 1;
}
.user-avatar {
  font-weight: 600;
  font-size: 2em;
}
.header-dash__logo-btn {
  background: transparent;
  border: none;
  color: var(--color-black);
  font-size: 0.8rem;
  font-style: italic;
  margin-right: auto;
  transition: width 0.3s ease-in 0s;
}
.header-dash__side-logo {
  max-width: 160px;
  cursor: pointer;
  text-align: center;
}
.header-dash__farm-select {
  color: rgb(255, 255, 255);
  border: none;

  text-transform: capitalize;
  min-width: 20%;
  border-radius: 10px;
}

.header-dash__farm-select ul{
  color: black !important;
}

.header-dash__profile-img {
  margin: 0;
  padding: 0;
}

.header-dash__profile-card {
  opacity: 1.0;
  background-color: white;
  border-radius: 5px;
  position: absolute;
  top: 2vh;
  right: 2vh;
  padding: 10px 20px;
  height: 200px;
  box-shadow: 1px 8px 13px 1px rgba(181, 181, 181, 0.75);
  -webkit-box-shadow: 1px 8px 13px 1px rgba(181, 181, 181, 0.75);
  -moz-box-shadow: 1px 8px 13px 1px rgba(181, 181, 181, 0.75);
}

@media only screen and (max-width: 425px) {
  /* For mobile phones: */
  .header-dash__side-logo, .header-dash__logo-btn{
    display: none;
  }

  .header-dash__farm-select {
    width: 100%;
  }
}
</style>