const logisticsStore = {
	state : {
		currentSelectedWeek : -1,
		selectedPlans : [],
		selectedVariantFitler : "",
	},
	mutations : {
		setCurrentSelectedWeek(state, newcurrentSelectedWeek)
		{
			state.currentSelectedWeek = newcurrentSelectedWeek
		},
		setSelectedPlans(state, newselectedPlans)
		{
			state.selectedPlans = newselectedPlans
		},
		setSelectedVariantFitler(state, newselectedVariantFitler)
		{
			state.selectedVariantFitler = newselectedVariantFitler
		},
	}
}

export default logisticsStore;
