<template>
  <div id="app">
    <div v-if="loggedIn" id="topbar">
      <Topbar
        v-if="loggedIn"
        :farms="farms"
        :user="user"
        :role="role"
        :selected_farm="selected_farm"
        :farm_select="farm_select"
        @updateFarm="farmUpdate"
        @logout="logOut"
      />
    </div>
    <div v-if="loggedIn" id="leftbar">
      <Sidebar
        v-if="loggedIn"
        :selected_farm="selected_farm"
        :user_role="role"
        @logout="logOut"
      />
    </div>
    <div :class="{'app__content' : loggedIn, 'app__content--safe' : !loggedIn}">
      <transition name="fade" mode="out-in">
        <router-view @updatefarmSelect="updatefarmSelect"> </router-view>
      </transition>  
    </div>
  </div>
</template>

<script>
import Sidebar from "./components/Sidebar.vue";
import axios from "axios";
import router from "./router";
import Topbar from "./components/Topbar.vue";

export default {
  name: "App",
  components: {
    Sidebar,
    Topbar,
  },
  props: [],
  data() {
    return {
      farms: [],
      selected_farm: localStorage.getItem('selected_farm'),
      farm_select: false,
      user: "",
      role: "",
      loggedIn: false,
      hideBackground: false,
    };
  },
  mounted() {
    this.isloggedIn();
  },
  methods: {
    updatefarmSelect(newValue){
      console.log("updateFarmSelect: ", newValue);
      this.farm_select = newValue;
    },
    farmUpdate(newValue) {
      console.log("Farm change: ", newValue);
      router.replace({
        name: this.$route.name,
        params: { farm: newValue },
        query: this.$route.query,
      });
      this.selected_farm = newValue;
      localStorage.setItem('selected_farm', newValue);
    },
    isloggedIn() {
      axios
        .get("/authenticated")
        .then((response) => {
          this.user = response.data.user.username;
          this.role = response.data.user.role;
          const user_farms = response.data.user.farms;
          const server_farms = response.data.farms;
          this.farms = [];
          for(var i in user_farms){
            var uFarm = user_farms[i];
            if(server_farms.includes(uFarm)){
              this.farms.push(uFarm);
            }
          }

          if (!this.farms.includes(this.selected_farm)){
            this.selected_farm = this.farms[0];
          }

          this.loggedIn = true;
          localStorage.setItem("user", this.user);
          console.log("this.loggedIn", this.loggedIn);

          if(router.currentRoute.name == "login"){
            console.log("Redirecting to home in 2 seconds");
            setTimeout(router.replace("/"), 2000);
          }
        })
        .catch(() => {
          this.loggedIn = false;
          router.replace({ name: "login" }).catch(() => {});
        });
    },
    logOut(){
      axios
        .get(`/logout`)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          this.loggedIn = false;
          router.replace({ name: "login" }).catch(() => {});          
        })
        .catch((e) => {
          console.log(e);
          this.loggedIn = false;
          router.replace({ name: "login" }).catch(() => {});          
        });
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--color-material-darkgrey);
  font-family: "Poppins", sans-serif;
  background-color: var(--color-material-white);
}
#topbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  z-index: 900;
  font-size: 13px;
  background: rgba(240, 255, 240, 0.5);
  backdrop-filter: blur(6px);
  user-select: none;
}
#leftbar {
  position: fixed;
  top: 75px;
  left: 0;
  z-index: 100;
  height: 100%;
  background-color: rgba(240, 255, 240, 0.5);
  backdrop-filter: blur(6px);
}
.app__content {
  box-sizing: border-box;
  font-size: 13px;
  margin: 0px;
  user-select: none;
  /* overflow: hidden; */
  margin-top: 80px;
  margin-left: 60px;
  padding: 1em;
  /* firefox scrollbar */
  scrollbar-color: var(--color-lightgrey);
  scrollbar-width: thin;
  height: 100%;
}

.app__content--safe {
  /* box-sizing: border-box; */
  font-size: 13px;
  margin: 0px;
  user-select: none;
  /* firefox scrollbar */
  scrollbar-color: var(--color-lightgrey);
  scrollbar-width: thin;
  height: 100%;
}
</style>

<style>
* {
  --color-material-darkgrey: #484848;
  --color-material-blue: #2196f3;
  --color-material-white: white;
  --color-lightgrey: #e7ecda;
  --color-dark-green: rgb(15, 109, 15);
  --color-dark-grey: rgb(105, 105, 105);
  --color-black: black;
}
/* Public global styling / overrides */
body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.fullscreen {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  background-color: var(--color-material-white);
  z-index: 999;
}

.form-modal {
  padding: 5px 10px;
  border-bottom: 1px solid #c8c8c8;
}
.form-modal label {
  text-align: right;
}
.btn-submit {
  float: right;
  margin: 10px;
}
.btn-add {
  margin: 10px;
}
.box-shadow-1 {
  box-shadow: -1px 8px 19px 11px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: -1px 8px 19px 11px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: -1px 8px 19px 11px rgba(0, 0, 0, 0.13);
}
.box-shadow-2 {
  box-shadow: 2px 2px 5px 1px rgba(191, 190, 190, 1);
  -webkit-box-shadow: 2px 2px 5px 1px rgba(191, 190, 190, 1);
  -moz-box-shadow: 2px 2px 5px 1px rgba(191, 190, 190, 1);
}
.scroll {
  scrollbar-color: var(--color-lightgrey);
  scrollbar-width: thin;
}
.scroll::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
  height: 1px;
}
.scroll::-webkit-scrollbar-track {
  background: var(--color-lightgrey);
}
.scroll::-webkit-scrollbar-thumb {
  background-color: var(--color-dark-grey); /* color of the scroll thumb */
}
.scroll-white::-webkit-scrollbar-track {
  background: var(--color-material-white);
}
.scroll-white::-webkit-scrollbar-thumb {
  background-color: var(--color-lightgrey); /* color of the scroll thumb */
  border: 0px solid var(--color-lightgrey); /* creates padding around scroll thumb */
}

.vdp-datepicker input{
	width: 100%;
  padding: 6px;
}

/* this is parent for multiple pages that uses one style */
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}
.maincard {
  background-color: var(--color-material-white);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 10px;
}

/**Pulse animation */
.pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.error {
  color: red;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

@media only screen and (max-width: 425px) {
  /* For mobile phones: */
  .scroll::-webkit-scrollbar {
    height: 1px;
  }

}

</style>
