<template >
  <div class="sidebar">
    <div>
      <!-- List of menu items -->
      <div class="sidebar__menu-items">
        <!-- Link to Crops pages -->
        <router-link
          :to="{ name: 'crops', params: { farm: selectedFarm } }"
          active-class="active"
          exact
          tag="button"
          class="sidebar__side-btn"
        >
          <span class="sidebar__link-container">
            <i class="fas fa-water"></i>
          </span>
          <span>
            <div class="sidebar__title-container">Crops</div>
          </span>
        </router-link>

        <!-- Link to seed inventory pages -->
        <router-link
          :to="{ name: 'seedinventory', params: { farm: selectedFarm } }"
          active-class="active"
          exact
          tag="button"
          class="sidebar__side-btn"
        >
          <span class="sidebar__link-container">
            <i class="fas fa-cubes"></i>
          </span>
          <span>
            <div class="sidebar__title-container">Seeds Inventory</div>
          </span>
        </router-link>

       <!-- Link to seed growareas pages -->
        <router-link
          :to="{ name: 'growareas', params: { farm: selectedFarm } }"
          active-class="active"
          exact
          tag="button"
          class="sidebar__side-btn"
        >
          <span class="sidebar__link-container">
           <i class="fas fa-solar-panel"></i>
          </span>
          <span>
            <div class="sidebar__title-container">Grow Areas</div>
          </span>
        </router-link>


      <!-- Link to seed HarvestStation pages -->
        <router-link
          :to="{ name: 'yield', params: { farm: selectedFarm } }"
          active-class="active"
          exact
          tag="button"
          class="sidebar__side-btn"
        >
          <span class="sidebar__link-container">
            <i class="fas fa-weight"></i>
          </span>
          <span>
            <div class="sidebar__title-container">Yield</div>
          </span>
        </router-link>

        <router-link
          :to="{ name: 'harvest', params: { farm: selectedFarm } }"
          active-class="active"
          exact
          tag="button"
          class="sidebar__side-btn"
        >
          <span class="sidebar__link-container">
            <i class="fas fa-balance-scale-right"></i>
          </span>
          <span>
            <div class="sidebar__title-container">Harvest</div>
          </span>
        </router-link>

         <!-- Link to seed task dashboard page -->
         <router-link
          :to="{ name: 'tasks', params: { farm: selectedFarm } }"
          active-class="active"
          exact
          tag="button"
          class="sidebar__side-btn"
        >
          <span class="sidebar__link-container">
           <i class="fas fa-check-square"></i>
          </span>
          <span>
            <div class="sidebar__title-container">Tasks</div>
          </span>
        </router-link>

        <!-- Link to variant management pages -->
        <router-link
          :to="{ name: 'variants', params: { farm: selectedFarm }  }"
          active-class="active"
          exact
          tag="button"
          class="sidebar__side-btn"
          v-show="user_role == 'admin'"
        >
          <span class="sidebar__link-container">
            <i class="fas fa-seedling"></i>
          </span>
          <span>
            <div class="sidebar__title-container">Variants</div>
          </span>
        </router-link>

        <!-- Link to usermangement pages -->
        <router-link
          :to="{ name: 'users', params: { farm: selectedFarm }  }"
          active-class="active"
          exact
          tag="button"
          class="sidebar__side-btn"
          v-show="user_role == 'admin'"
        >
          <span class="sidebar__link-container">
            <i class="fas fa-users"></i>
          </span>
          <span>
            <div class="sidebar__title-container">Users</div>
          </span>
        </router-link>

         <!-- Link to planting plan pages -->
         <router-link
          :to="{ name: 'plantingplan',params: { farm: selectedFarm } }"
          active-class="active"
          exact
          tag="button"
          class="sidebar__side-btn"
        >
          <span class="sidebar__link-container">
           <i class="fas fa-seedling"></i>
          </span>
          <span>
            <div class="sidebar__title-container">Planting Plan</div>
          </span>
        </router-link>

         <!-- Link to sales and logistic pages -->
         <router-link
          :to="{ name: 'logistics', params: { farm: selectedFarm } }"
          active-class="active"
          exact
          tag="button"
          class="sidebar__side-btn"
        >
          <span class="sidebar__link-container">
           <i class="fas fa-solar-panel"></i>
          </span>
          <span>
            <div class="sidebar__title-container">Sales and Logistics</div>
          </span>
        </router-link>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  props: ["selected_farm", "user_role"],
  data() {
    return {
      selectedFarm: this.selected_farm,
      showUsers: false
    };
  },
  watch: {
    selected_farm: function(newVal){
      this.selectedFarm = newVal;
    }
  },
  filters: {
    uppercase: function (v) {
      return v.toUpperCase();
    },
    //filter here will make letters uppercase
    //have to do like this since no function call therefore no return variable to
    //return toUppercase to (vue doesnt allow that)
  },
  methods: {
  }
};
</script>

<style>
.sidebar {
  user-select: none;
  width: 60px;
  transition: width 0.3s ease-out 0s;
  margin-top: 5vh;
}
.sidebar:hover {
  width: 220px;
  transition: width 0.3s ease;
}
.sidebar__title-container {
  display: unset;
  margin-left: 20px;
}
.sidebar__side-btn {
  color: var(--color-dark-grey);
  border: none;
  padding: 5px 0px;
  margin: 0px 12px;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  padding-left: 10px;
  transition: color 0.5s;
  display: flex;
  max-height: 35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}
.sidebar__menu-items {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 70vh;
}
.sidebar__menu-items > * {
  margin-top: 10px;
}
.sidebar__menu-items .sidebar__logout-btn {
  margin-top: auto;
  background-color: transparent;
  border: none;
  color: black;
  text-align: center;
  font-size: 20px;
}
.sidebar__menu-items .sidebar__logout-btn:hover {
  color: red;
}
.sidebar__side-btn:focus {
  outline: none;
}
.sidebar__menu-items :hover {
  color: red;
  transition: color 0.5s ease-in-out;
}
.sidebar__side-btn.active {
  position: relative;
  background-color: #dddddd;
  color: rgb(15, 109, 15);
  font-weight: 500;
  border-radius: 2.5px;
  transition: color 0.5s ease;
}
.sidebar__side-btn.active :hover {
  color: rgb(15, 109, 15);
  transition: color 0.5s ease;
}
</style>
