import Vue from 'vue'
import App from "./App.vue"
import router from "./router"
import store from "./store"
import './plugins/axios'
import './plugins/bootstrap-vue'
import './plugins/vue-select'
import MultiSelect from 'vue-multiselect'
import './plugins/vue-good-table'
import '@fortawesome/fontawesome-free/js/all.js'
import 'mqtt'
import '@/plugins/apexcharts'
import axios from 'axios';
import VueApexCharts from 'vue-apexcharts'
import { VueQueryPlugin } from '@tanstack/vue-query'

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL || "api";
axios.defaults.timeout = 10000;

// request interceptor
axios.interceptors.request.use( async config => { 
        if(process.env.VUE_APP_USE_NGINX == 1){
            config.baseURL = window.location.origin + '/api'; 
        }
        else{
            config.baseURL = axios.defaults.baseURL
        }
        return config;
    }, error => Promise.reject(error) 
);

// response interceptor
axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response && error.response.data) {
        // if unauthorized (401), go to login
        if(error.response.status == 401){
            router.replace({name: "login"});
        }
        // if forbidden (403), go to error
        else if(error.response.status == 403){
            router.replace({name: "error"});
        }
        else if(error.response.status == 500){
            router.replace({name: "error"});
        }
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
Vue.use(VueQueryPlugin);
Vue.use(VueApexCharts);
Vue.component('ApexChart', VueApexCharts);
Vue.component('MultiSelect', MultiSelect);
