import tags_json from "../assets/tags.json";

const harvestStore = {
	state : {
		harvest: null,
		batch: null,
		hsId: null,
		zone: null,
		growarea: null,
		variant: null,
		massmod: tags_json[0],
		token: null,
		expiry: null
	},
	mutations : {
		setHarvestToEdit(state, newHarvest){
			state.harvest = newHarvest;
		},
		setBatchforHarvest(state, newBatch){
			state.batch = newBatch;
		},
		setZone(state, newZone){
			state.zone = newZone;
		},
		setGrowarea(state, newGA){
			state.growarea = newGA;
		},
		setVariant(state, newVAR){
			state.variant = newVAR;
		},
		setMassMod(state, newMassMod){
			state.massmod = newMassMod;
		},
		setHSID(state, newHS){
			state.hsId = newHS;
		},
		setToken(state, newTkn){
			state.token = newTkn;
		},
		setExpiry(state, newExp){
			state.expiry = newExp;
		}
	}
}

export default harvestStore;