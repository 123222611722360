const plantingPlanStore = {
	state : {
		farm: "", // current selected farm
		selectedCycle : null, // current selected cycle
		selectedZone : null, // current selected planting plan zone
		mobileCycleSlideIdx : 0, // current mobile swipe index
		newCycleCreation: false, // switch for creation mode
		newCycleObject : { // new cycle object upon creation
			cycle: {
				name: "",
				state: "",
				cycleZone : "",
				germinationStartCalandarWeek : "", 
				germinationEndCalandarWeek : "", 
				propagationStartCalandarWeek : "",
				propagationEndCalandarWeek : "", 
				harvestStartCalandarWeek : "", 
				harvestEndCalandarWeek : ""
			},
			cycleVariants : [],
			cycleNote : {message : ""},
		},
		newlyAddedCycles : [], // new added cycles for the current session
		newlyAddedVariants: [], // new added variants for the currenst session
		newlyAddedZones: [], // new added zones for the current session
	},
	mutations : {
		setFarm(state, newFarm)
		{
			state.farm = newFarm
		},
		setSelectedCycle(state, newCycle)
		{
			state.selectedCycle = newCycle
		},
		setSelectedZone(state, newZone)
		{
			state.selectedZone = newZone
		},
		setMobileCycleSlideIdx(state, newIdx)
		{
			state.mobileCycleSlideIdx  = newIdx
		},
		setNewCycleCreation(state, newCC)
		{
			state.newCycleCreation = newCC
		},
		setNewCycleObject(state, newCO)
		{
			state.newCycleObject = newCO;
		},
		setNewlyAddedCycles(state, newnas)
		{
			state.newlyAddedCycles = newnas;
		},
		setNewlyAddedVariants(state, newnas)
		{
			state.newlyAddedVariants = newnas;
		},
		setNewlyAddedZones(state, newnas)
		{
			state.newlyAddedZones = newnas;
		}
	}
}

export default plantingPlanStore;
